/* eslint-disable import/order */
/* globals jsTranslations, JS_DEBUG */

// Apparently as of version 7.2.5 this is not part of the babel-polyfill.
// see https://stackoverflow.com/q/53331180
if (window.NodeList && !NodeList.prototype.forEach) {
    NodeList.prototype.forEach = Array.prototype.forEach;
}

// Polyfill `remove` method.
// see https://developer.mozilla.org/en-US/docs/Web/API/ChildNode/remove#Polyfill
if (!Element.prototype.hasOwnProperty('remove')) {
    Object.defineProperty(Element.prototype, 'remove', {
        configurable: true,
        enumerable: true,
        writable: true,
        value: function remove() {
            if (this.parentNode !== null) {
                this.parentNode.removeChild(this);
            }
        },
    });
}

import ScrollableMobileMenu from './scrollable-mobile-menu';
import '../polyfill/mouse-event';
import mdcAutoInit from '@material/auto-init';
import {MDCSimpleMenu} from '@material/menu';
import {MDCDialog} from '@material/dialog';
import {MDCSnackbar} from '@material/snackbar';
import {MDCRipple} from '@material/ripple';

// Initialize namespace
var Fx = window.Fx || {};
Fx.Dashboard = Fx.Dashboard || {};

/**
 * Main Dashboard App Module
 */
Fx.Dashboard.App = (function () {
    /**
     * App Module constructor
     *
     * @constructor
     */
    function App() {
        var notificationSnackBar = document.getElementById(
            'flx-notifications-snackbar',
        );
        if (notificationSnackBar) {
            this.flxNotificationSnackbar =
                MDCSnackbar.attachTo(notificationSnackBar);
        }

        if (document.getElementById('top-tab-bar')) {
            new ScrollableMobileMenu();
        }
        this.initializeProfileMenuBar();
        this.initializeCopyProfileLink();
        this.initFlashes();
        this.tabCode = 'tabCode';
        this.initNewMessageRingTone();
        this.attachRippleBehaviour();
        this.attachGlobalDialogs();
        this.fixCloseMenuOnMobileTouch();
        this.initContextMenus(document.body);
        this.initGenericDialogs();
        if (
            document.querySelector('#go-online') &&
            document.querySelector('#go-offline')
        ) {
            this.expertStatusToggle();
        }

        mdcAutoInit();
    }

    /**
     * Initialize all registered confirm dialogs. For documentation, see {@link #addConfirmDialog}.
     */
    App.prototype.initGenericDialogs = function () {
        if (Fx && Fx.Data && Fx.Data.ConfirmDialogs) {
            Fx.Data.ConfirmDialogs.forEach(App.prototype.addConfirmDialog);
        }
    };

    /**
     * Add a generic confirm dialog. You can use this function like this:
     * <script>
     *      window.Fx = window.Fx || {};
     *      window.Fx.Data = window.Fx.Data || {};
     *      window.Fx.Data.ConfirmDialogs = window.Fx.Data.ConfirmDialogs || [];
     *
     *      var object = {...};
     *      Fx.Data.ConfirmDialogs.push(object);
     * </script>
     *
     * or from PHP context (view):
     * $object = json_encode(
     *     [...],
     *     JSON_UNESCAPED_SLASHES | JSON_UNESCAPED_UNICODE
     * );
     * $js = <<<JS
     *      window.Fx = window.Fx || {};
     *      window.Fx.Data = window.Fx.Data || {};
     *      window.Fx.Data.ConfirmDialogs = window.Fx.Data.ConfirmDialogs || [];
     *
     *      Fx.Data.ConfirmDialogs.push(${object});
     * JS;
     * $this->registerJs($js, \yii\web\View::POS_BEGIN);
     *
     * In the examples above, `object` is an object as described by the parameters below.
     *
     * @param {string} uid - A unique ID for the dialog. No spaces, only lowercase letters.
     * @param {string} buttonSelector - The selector for the dom element where on click the confirm should open.
     * @param {string} title - dialog title. Must be XSS protected!
     * @param {string} body - dialog body. Must be XSS protected!
     * @param {string} acceptButton - dialog text for accept button. Must be XSS protected!
     * @param {string} cancelButton - dialog text for cancel button. Must be XSS protected!
     * @param {function} onAccept
     * @param {function} [onReject]
     */
    App.prototype.addConfirmDialog = function ({
        uid,
        buttonSelector,
        title,
        body,
        acceptButton,
        cancelButton,
        onAccept,
        onReject = null,
    }) {
        const asideId = `mdc-dialog-${uid}`;

        document.body.insertAdjacentHTML(
            'beforeend',
            `
            <aside
                id="${asideId}"
                class="mdc-dialog"
                role="alertdialog"
                aria-hidden="true"
                aria-labelledby="mdc-dialog-default-label"
                aria-describedby="mdc-dialog-default-description"
            >
                <div class="mdc-dialog__surface">
                    <header class="mdc-dialog__header">
                        <h2 id="mdc-dialog-default-label" class="mdc-dialog__header__title">
                            ${title}
                        </h2>
                    </header>
                    <section id="mdc-dialog-default-description" class="mdc-dialog__body">
                        ${body}
                    </section>
                    <footer class="mdc-dialog__footer">
                        <button type="button" class="mdc-button mdc-dialog__footer__button mdc-dialog__footer__button--cancel">
                            ${cancelButton}
                        </button>
                        <button type="button" class="mdc-button mdc-dialog__footer__button mdc-dialog__footer__button--accept">
                            ${acceptButton}
                        </button>
                    </footer>
                </div>
                <div class="mdc-dialog__backdrop"></div>
            </aside>
        `,
        );

        const button = document.querySelector(buttonSelector);
        if (!button) {
            console.error(
                '[addGenericConfirmDialog] Button not found!',
                buttonSelector,
            );
            return;
        }

        const aside = document.querySelector(`#${asideId}`);
        if (!aside) {
            console.error(
                '[addGenericConfirmDialog] Aside not found! Uid may not contain spaces?',
                {uid, asideId},
            );
            return;
        }

        button.addEventListener('click', () => {
            const dialog = new MDCDialog(aside);

            dialog.listen('MDCDialog:accept', onAccept);
            if (onReject) {
                dialog.listen('MDCDialog:cancel', onReject);
            }

            dialog.show();
        });
    };

    /**
     * Connects context menus and buttons under `root`.
     *
     * @param {HTMLElement} root - Root element.
     */
    App.prototype.initContextMenus = function (root) {
        var buttons = root.querySelectorAll('.flx-context-menu-button');

        var setupMenu = function (button) {
            var element = button.parentNode.querySelector('.flx-context-menu');

            if (!element) {
                console.warn(
                    'Meetings - Cannot find corresponding context menu element for button.',
                    button,
                );
                return;
            }

            var contextMenu = new MDCSimpleMenu(element);

            button.addEventListener('click', () => {
                contextMenu.open = true;
            });
        };

        buttons.forEach(setupMenu);
    };

    /**
     * Show snackbar style notification
     *
     * @param {string} message
     * @param {int} timeout = 2750
     * @param {bool} forceClose = false
     * @param {bool} showActionButton = false
     * @param {string} actionButtonText = jsTranslations.snackbarDismiss
     * @param {bool/function} actionButtonHandler
     */
    App.prototype.showNotification = function (
        message,
        {
            timeout,
            forceClose,
            showActionButton,
            actionButtonText,
            actionButtonHandler,
            browserNotificationTitle,
        } = {},
    ) {
        timeout = timeout || 2750;
        forceClose = forceClose || false;
        showActionButton = showActionButton || false;
        var actionText = null;
        var actionHandler = null;
        if (showActionButton) {
            actionText = actionButtonText || jsTranslations.snackbarDismiss;
            actionHandler =
                actionButtonHandler ||
                function () {
                    return;
                };
            timeout = actionButtonHandler ? 4000 : 90000;
        }
        this.flxNotificationSnackbar.dismissesOnAction = true;
        var data = {
            message,
            timeout,
            multiline: message.length > 80,
            actionText,
            actionHandler,
        };
        this.flxNotificationSnackbar.show(data);
        if (browserNotificationTitle) {
            this.showBrowserNotification({
                title: browserNotificationTitle,
                message,
            });
        }
        if (forceClose) {
            this.flxNotificationSnackbar.foundation_.firstFocus_ = false;
        }
    };

    /**
     * Trigger Notifications on the Browser
     * @param {string} message
     * @param {string} title
     */
    App.prototype.showBrowserNotification = function ({title, message}) {
        if (document.visibilityState === 'visible') {
            return;
        }
        if (!('Notification' in window)) {
            if (JS_DEBUG) {
                console.info(
                    'Browser notifications are not allowed on this browser',
                );
            }
        } else if (Notification.permission === 'granted') {
            new Notification(title, {
                body: message,
            });
        } else if (Notification.permission !== 'denied') {
            Notification.requestPermission((permission) => {
                if (permission === 'granted') {
                    this.showBrowserNotification(message);
                }
            });
        }
    };

    /**
     * Prepend to the Tab Title within parenthesis if message is not empty
     * in case is empty remove the message within the parenthesis
     */
    App.prototype.prependMessageToTabTitle = function (message) {
        const isThereAMessage = document.title.indexOf(')') !== -1;
        let currentTitle = document.title;

        if (isThereAMessage) {
            currentTitle = currentTitle.split(')')[1];
        }

        if (message && document.visibilityState !== 'visible') {
            currentTitle = '(' + message + ')' + currentTitle;
        }
        document.title = currentTitle;
    };

    /**
     * Initialize Ripples
     */
    App.prototype.attachRippleBehaviour = function () {
        var buttons = document.querySelectorAll(
            '.mdc-button:not([data-demo-no-js]), .mdc-fab:not([data-demo-no-js])',
        );
        for (var i = 0; i < buttons.length; i++) {
            MDCRipple.attachTo(buttons[i]);
        }
    };

    /**
     * Generates an Id for current Tab and save it on localStorage
     * @private
     */
    App.prototype._setTabCodeAndSave = function () {
        let tabs = localStorage.getItem(this.tabCode);
        let tabsOpen = tabs ? JSON.parse(tabs) : [];

        this.tabRandomKey = Math.random() * 10000;
        tabsOpen.push(this.tabRandomKey);
        localStorage.setItem(this.tabCode, JSON.stringify(tabsOpen));
    };

    /**
     * Eliminate current Tab from list of Active Tabs
     * @private
     */
    App.prototype._removeTabCodeAndSave = function () {
        let tabs = localStorage.getItem(this.tabCode);
        let tabsOpen = tabs ? JSON.parse(tabs) : [];

        tabsOpen.splice(tabsOpen.indexOf(this.tabRandomKey), 1);
        localStorage.setItem(this.tabCode, JSON.stringify(tabsOpen));
    };

    /**
     * Setting the Ring on the last Tab added
     * @returns {boolean}
     * @private
     */
    App.prototype._shouldTabRing = function () {
        let tabs = localStorage.getItem(this.tabCode);
        let tabsOpen = tabs ? JSON.parse(tabs) : [];

        return tabsOpen.indexOf(this.tabRandomKey) === tabsOpen.length - 1;
    };

    /**
     * Initialize workflow for new message
     */
    App.prototype.initNewMessageRingTone = function () {
        const localStorageKey = 'audioNotificationPlaying';
        const newMessageAudio = document.getElementById('new-message');
        const audioTimeOutSec = 5000;
        let audioNotificationPlaying = false;

        this._setTabCodeAndSave();

        if (!newMessageAudio) {
            return;
        }

        newMessageAudio.addEventListener('play', () => {
            audioNotificationPlaying = true;
            localStorage.setItem(
                localStorageKey,
                JSON.stringify(audioNotificationPlaying),
            );
        });

        newMessageAudio.addEventListener('ended', () => {
            audioNotificationPlaying = false;
            setTimeout(() => {
                localStorage.setItem(
                    localStorageKey,
                    JSON.stringify(audioNotificationPlaying),
                );
            }, audioTimeOutSec);
        });

        window.addEventListener('unload', () => {
            localStorage.removeItem(localStorageKey);
            this._removeTabCodeAndSave();
        });
    };

    /**
     * Ring a tone for new Message
     */
    App.prototype.ringNewMessage = function () {
        const newMessageAudio = document.getElementById('new-message');
        let audioNotification = JSON.parse(
            localStorage.getItem('audioNotificationPlaying'),
        );

        if (!newMessageAudio) {
            return;
        }

        if (
            this._shouldTabRing() &&
            !audioNotification &&
            document.visibilityState !== 'visible'
        ) {
            newMessageAudio.play();
        }
    };

    /**
     * Initialize Profile Menu
     */
    App.prototype.initializeProfileMenuBar = function () {
        const profileMenuSelector = document.querySelector('#profile-menu');
        const toggle = document.querySelector('.toggle');

        if (profileMenuSelector && toggle) {
            const menu = new MDCSimpleMenu(profileMenuSelector);

            toggle.addEventListener('click', () => {
                menu.open = !menu.open;
            });
        }
    };

    /**
     * "Copy Link to My Profile" buttons handler
     */
    App.prototype.initializeCopyProfileLink = function () {
        const FORCE_CLOSE = true;
        const SHOW_CLOSE_BTN = true;
        const copyButtons = document.querySelectorAll('.copy-profile-link-btn');

        for (let btn of copyButtons) {
            btn.addEventListener(
                'click',
                (event) => {
                    event.preventDefault();

                    const linkCopyBtnId = btn.getAttribute('id');
                    const virtualInput = document.createElement('input');
                    virtualInput.setAttribute('type', 'text');
                    virtualInput.setAttribute('value', btn.href.trim());
                    virtualInput.setAttribute('contenteditable', true);
                    document.body.appendChild(virtualInput);

                    // iOS devices require special treatment to make
                    // `execCommand('copy')` work, see https://stackoverflow.com/a/52863358.
                    if (
                        /ipad|iphone/i.test(navigator.userAgent) ||
                        (navigator.platform === 'MacIntel' &&
                            navigator.maxTouchPoints > 1 &&
                            !window.MSStream)
                    ) {
                        const range = document.createRange();
                        range.selectNodeContents(virtualInput);
                        const selection = window.getSelection();
                        selection.removeAllRanges();
                        selection.addRange(range);
                        virtualInput.setSelectionRange(0, 999999);
                    } else {
                        virtualInput.select();
                    }

                    try {
                        document.execCommand('copy');
                        this.showNotification(
                            jsTranslations.profileLinkCopySuccess,
                            {
                                timeout: 2750,
                                forceClose: FORCE_CLOSE,
                            },
                        );
                    } catch (err) {
                        this.showNotification(
                            jsTranslations.profileLinkCopyFailure,
                            {
                                timeout: 10000,
                                forceClose: FORCE_CLOSE,
                                showActionButton: SHOW_CLOSE_BTN,
                            },
                        );
                    } finally {
                        // Cleanup
                        document.body.removeChild(virtualInput);
                    }
                },
                {capture: true},
            );
        }
    };

    /**
     * Initialize Yii flash using MDC snackbar
     */
    App.prototype.initFlashes = function () {
        if (Fx.Dashboard.Data && Fx.Dashboard.Data.FlashMessages) {
            for (var i = 0; i < Fx.Dashboard.Data.FlashMessages.length; i++) {
                // 50ms reading time for each character in message,with minimum of 2750 millisecond.
                var messageLength = Fx.Dashboard.Data.FlashMessages[i].length;
                var timeOut =
                    messageLength * 50 > 2750 ? messageLength * 50 : 2750;
                this.showNotification(Fx.Dashboard.Data.FlashMessages[i], {
                    timeout: timeOut,
                });
            }
        }
    };

    /**
     * Show Subscription Dialog on click
     */
    App.prototype.attachGlobalDialogs = function () {
        var subscriptionDialogHandler = document.getElementById(
            'subscription-graced',
        );
        if (subscriptionDialogHandler) {
            subscriptionDialogHandler.addEventListener('click', () => {
                var dialog = new MDCDialog(
                    document.getElementById('subscription-graced-dialog'),
                );
                dialog.show();
            });
        }
    };

    /**
     * When menu is open, prevent default clicks outside but dispatch click to close it
     */
    App.prototype.fixCloseMenuOnMobileTouch = function () {
        var touchMoved = false;
        var onAction = function (event) {
            if (touchMoved === true) {
                touchMoved = false;
                return;
            }

            // Return when event was triggered to prevent loop
            if (event.type === 'click' && !event.clientX && !event.clientY) {
                return;
            }

            var openMenu = document.querySelector('.mdc-simple-menu--open');

            if (!openMenu) {
                return;
            }

            var isTouchOutsideMenu = !openMenu.contains(event.target);

            if (isTouchOutsideMenu) {
                event.preventDefault();

                this.closeMenu();
            }
        }.bind(this);

        /**
         * 3rd param true sets it to capture instead of bubble, which we need here
         *
         * @see https://javascript.info/bubbling-and-capturing
         */
        window.addEventListener('touchend', onAction, true);
        window.addEventListener(
            'touchmove',
            () => {
                touchMoved = true;
            },
            true,
        );
    };

    /**
     * Trigger menu closing
     *
     * @private
     */
    App.prototype.closeMenu = function () {
        document.querySelector('.flx-sections').dispatchEvent(
            new MouseEvent('click', {
                view: window,
                bubbles: true,
                cancelable: true,
            }),
        );
    };

    /**
     * Preparing custom event of given type
     *
     * @param eventType
     * @return event
     */
    App.prototype.prepareCustomEvent = function (eventType) {
        var event;
        if (typeof CustomEvent === 'function') {
            event = new CustomEvent(eventType);
        } else {
            event = document.createEvent('Event');
            event.initEvent(eventType, true, true);
        }

        return event;
    };

    /**
     * Toggle user status on click event
     */
    App.prototype.expertStatusToggle = function () {
        document.querySelector('#go-online').addEventListener('click', () => {
            this.closeMenu();
        });

        document.querySelector('#go-offline').addEventListener('click', () => {
            this.closeMenu();
        });
    };

    return new App();
})();

/**
 * Dashboard Module for extended gestures on Tab Menu
 */
export default class ScrollableMobileMenu {
    /**
     * @constructor
     */
    constructor() {
        this.initializeUIElements();
        this.setScrollArrows();
        this.enableScrollBarMenu();
        this.registerEvents();
    }

    /**
     * Initialize elements used by tge Scroll Mobile Menu
     */
    initializeUIElements() {
        this.wrapperWithArrows = document.querySelector(
            '#top-tab-bar-scroller',
        );
        this.viewPort =
            document.getElementsByClassName('basic-demo-toolbar')[0];
        this.availableTabs = this.viewPort.querySelectorAll('.mdc-tab');
        this.selectedTabPosition = this.getSelectedTabPosition();
        // Arrow Elements
        this.backArrowElement = document.getElementsByClassName(
            'mdc-tab-bar-scroller__indicator--back',
        )[0];
        this.forwardArrowElement = document.getElementsByClassName(
            'mdc-tab-bar-scroller__indicator--forward',
        )[0];
        // latest Selected Position to scroll to
        this.latestSelectedPosTab = this.selectedTabPosition;
        this.scrollingBack = false;
    }

    /**
     * First initialization of scrollBar behaviour
     */
    enableScrollBarMenu() {
        const menu = document.getElementById('top-tab-bar');

        if (menu.clientWidth > this.viewPort.clientWidth) {
            this.viewPort.classList.add('show-scroll-menu');
            this.moveElementToPosition(this.getSelectedTabPosition());
            this.setScrollArrows();
        } else {
            this.viewPort.classList.remove('show-scroll-menu');
        }
    }

    /**
     * Returns position of selected Tab
     * @returns {number}
     */
    getSelectedTabPosition() {
        for (let pos = 0; pos < this.availableTabs.length; pos++) {
            if (this.availableTabs[pos].classList.contains('mdc-tab--active')) {
                return pos;
            }
        }
    }

    /**
     * Controls When to show and hide the Arrows for navigation
     */
    setScrollArrows() {
        const viewportRightBorder = this.viewPort.getBoundingClientRect().right;
        const lastElementRightBorder = Math.ceil(
            this.availableTabs[
                this.availableTabs.length - 1
            ].getBoundingClientRect().right,
        );

        if (this.viewPort.scrollLeft === 0) {
            this.backArrowElement.classList.remove(
                'mdc-tab-bar-scroller__indicator--enabled',
            );
        } else {
            this.backArrowElement.classList.add(
                'mdc-tab-bar-scroller__indicator--enabled',
            );
        }

        if (lastElementRightBorder > viewportRightBorder + 1) {
            this.forwardArrowElement.classList.add(
                'mdc-tab-bar-scroller__indicator--enabled',
            );
        } else {
            this.forwardArrowElement.classList.remove(
                'mdc-tab-bar-scroller__indicator--enabled',
            );
        }
    }

    /**
     * Register events related with clicks and others
     */
    registerEvents() {
        this.viewPort.addEventListener(
            'scroll',
            this.setScrollArrows.bind(this),
        );
        let _this = this;

        this.backArrowElement.addEventListener(
            'click',
            () => {
                _this.scrollBack();
            },
            false,
        );

        this.forwardArrowElement.addEventListener(
            'click',
            () => {
                _this.scrollForward();
            },
            false,
        );

        window.addEventListener('resize', () => {
            _this.enableScrollBarMenu(this);
            _this.setScrollArrows();
        });
    }

    /**
     * Implements the logic for scrolling back
     */
    scrollBack() {
        let currentPosition = this.getFirstVisibleTabPosition();
        if (this.latestSelectedPosTab === currentPosition) {
            currentPosition -= 1;
        }
        const prevElBoundaries =
            this.availableTabs[currentPosition].getBoundingClientRect();
        this.animate(-prevElBoundaries.width);
        this.latestSelectedPosTab = currentPosition;
        this.scrollingBack = true;
        this.setScrollArrows();
    }

    /**
     * Implements logic for scrolling forward
     */
    scrollForward() {
        let currentPosition = this.getLastVisibleTabPosition();
        if (
            this.latestSelectedPosTab === currentPosition &&
            !this.scrollingBack
        ) {
            currentPosition += 1;
        }
        const nextElBoundaries =
            this.availableTabs[currentPosition].getBoundingClientRect();
        this.animate(nextElBoundaries.width);
        this.latestSelectedPosTab = currentPosition;
        this.scrollingBack = true;
        this.setScrollArrows();
    }

    /**
     * Animation fn to make menu scroll smooth
     */
    animate(shift) {
        const maxSteps = 10;
        let animationId;
        let stepsCount = 0;

        const animationFn = () => {
            this.viewPort.scrollLeft += Math.round(shift / maxSteps, 10);
            stepsCount++;
            if (stepsCount < maxSteps) {
                animationId = requestAnimationFrame(animationFn);
            }
        };

        animationId = requestAnimationFrame(animationFn);
    }

    /**
     * Scroll the selected position on page load to the first position
     * @param elementPos
     */
    moveElementToPosition(elementPos) {
        let scrollPosition = 0;
        if (elementPos && elementPos > 0 && this.availableTabs[elementPos]) {
            scrollPosition =
                this.availableTabs[elementPos].getBoundingClientRect().left -
                this.wrapperWithArrows.getBoundingClientRect().left -
                12;
        }
        scrollPosition += this.backArrowElement.clientWidth;
        this.viewPort.scrollLeft = scrollPosition;
        this.setScrollArrows();
    }

    /**
     * Returns first Visible Tab on the Menu
     * @returns {number}
     */
    getFirstVisibleTabPosition() {
        const viewportLeftBorder = this.viewPort.getBoundingClientRect().left;

        return Array.from(this.availableTabs).reduce(
            (firstVisible, menuItem) => {
                let menuItemBoundaries = menuItem.getBoundingClientRect();
                return menuItemBoundaries.left > viewportLeftBorder
                    ? firstVisible - 1
                    : firstVisible;
            },
            this.availableTabs.length,
        );
    }

    /**
     * Returns last Visible Tabs on the Menu
     * @returns {number}
     */
    getLastVisibleTabPosition() {
        const viewportRightBorder = this.viewPort.getBoundingClientRect().right;

        return Array.from(this.availableTabs).reduce(
            (lastVisible, menuItem) => {
                let menuItemBoundaries = menuItem.getBoundingClientRect();
                return menuItemBoundaries.right < viewportRightBorder
                    ? lastVisible + 1
                    : lastVisible;
            },
            0,
        );
    }
}
